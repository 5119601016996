// 全局变量
let BASE_API = ""
if (process.env.NODE_ENV == "development") {

	BASE_API = 'http://dev1.vcaidao.com' 
	
} else if (process.env.NODE_ENV == "production") {
	
	 BASE_API = window.location.origin
}

export { BASE_API }
